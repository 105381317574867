<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:51:20
 * @LastEditors: Please set LastEditors
 -->
<template>
  <div class="figuretwo" v-if="you_xiaoping">
        <div class="xiaoping">
            <div class="tu2_con_left">
                <div class="tu2_con_left_tit">
                    <p class="xuanze_nianfen">
                        年份:
                        <el-select v-model="nianfen" placeholder="请选择" @change="nianfen_zhi">
                            <el-option
                                v-for="item in time_list"
                                :key="item"
                                :label="item"
                                :value="item">
                            </el-option>
                        </el-select>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <span v-show='yu_shangnian_duibi'>与上一年销售额对比</span>
                    </p>
                    <p class="yierji">
                        <span @click="dian_yiji" :style="yierji_panduan?'color:#FFB466;':''"><img :src="yierji_panduan?require('../../assets/huang_shixin.png'):require('../../assets/ccc_quan.png')">一级</span>
                        <span @click="dian_mingxi" :style="!yierji_panduan?'color:#FFB466;':''"><img :src="!yierji_panduan?require('../../assets/huang_shixin.png'):require('../../assets/ccc_quan.png')">明细</span>
                    </p>
                    <p class="paixu" @click="chanpin_mubiao_paixu">
                        产品销售目标排序
                        <!-- &nbsp;&nbsp;&nbsp;<img src="../../assets/ccc_quan.png" alt=""> -->
                    </p>
                </div>
                <div v-if="nianfen!=''" class="tu_text" v-show="danwei_shili">
                    <p>
                        <span>单位:{{danwei}}</span>
                    </p>
                    <p>
                        <span>本年目标销售额</span>
                        <span>本年目标销售额增长率</span>
                    </p>
                </div>
                <img v-if="nianfen==''" style="height:86%;width:100%;" src="../../assets/shili_tu2-1.png" alt="">
                <div v-if="nianfen!=''" class="bingpai_tu">
                    <div id="myCharts4" :style="{width:'48%',height:'80%',background:'#fff'}"></div>
                    <div id="myCharts5" :style="{width:'40%',height:'80%',background:'#fff'}"></div>
                </div>
                <ul v-if="nianfen!=''" class="shili"  v-show="danwei_shili">
                    <li>
                        <span style="background:#FFB466;"></span>目标
                    </li>
                    <li>
                        <span style="background:#6ABFA7;"></span>超出目标
                    </li>
                    <li>
                        <span style="background:#F16977;"></span>降低目标
                    </li>
                </ul>
            </div>
            <div class="tu2_con_right">
                <p v-if="nianfen!=''" class="tu2_con_right_tit">
                    <span class="span1">年份:{{nianfen}}</span>
                    <span class="span2">
                        <span @click="dian_yiji2" :style="yierji_panduan2?'color:#FFB466;':''"><img :src="yierji_panduan2?require('../../assets/huang_shixin.png'):require('../../assets/ccc_quan.png')">一级</span>
                        <span @click="dian_mingxi2" :style="!yierji_panduan2?'color:#FFB466;':''"><img :src="!yierji_panduan2?require('../../assets/huang_shixin.png'):require('../../assets/ccc_quan.png')">明细</span>
                    </span>
                </p>
                <p v-if="nianfen!=''" class="tu2_right_danwei">
                    <span>单位:{{danwei}}</span>
                    <span>本年目标销售额产品占比</span>
                    <span></span>
                </p>
                <img v-if="nianfen==''" style="margin-top:7%;height:86%;width:100%;" src="../../assets/shili_tu2-2.png" alt="">
                <div id="myCharts6" v-if='yierji_panduan2&&nianfen!=""' :style="{width:'80%',height:'80%',background:'#fff'}"></div>
                <div id="myCharts7" v-show='!yierji_panduan2&&nianfen!=""' :style="{width:'80%',height:'80%',background:'#fff'}"></div>
            </div>
        </div>
        <div class="quanping" v-if="quan_zia">    <!--v-if="quan_zia==1"-->
            <div class="box">
                <div class="guanbi" @click="dianji_guanbi">X</div>
                <p class="box_text">产品销售目标(二)</p>
                <div class="box_nei">
                    <div class="tu2_con_left">
                        <div class="tu2_con_left_tit">
                            <p class="xuanze_nianfen">
                                年份:
                                <el-select v-model="nianfen" placeholder="请选择" @change="nianfen_zhi">
                                    <el-option
                                        v-for="item in time_list"
                                        :key="item"
                                        :label="item"
                                        :value="item">
                                    </el-option>
                                </el-select>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <span v-show='yu_shangnian_duibi'>与上一年销售额对比</span>
                            </p>
                            <p class="yierji">
                                <span @click="dian_yiji" :style="yierji_panduan?'color:#FFB466;':''"><img :src="yierji_panduan?require('../../assets/huang_shixin.png'):require('../../assets/ccc_quan.png')">一级</span>
                                <span @click="dian_mingxi" :style="!yierji_panduan?'color:#FFB466;':''"><img :src="!yierji_panduan?require('../../assets/huang_shixin.png'):require('../../assets/ccc_quan.png')">明细</span>
                            </p>
                            <p class="paixu" @click="chanpin_mubiao_paixu">
                                产品销售目标排序
                                <!-- &nbsp;&nbsp;&nbsp;<img src="../../assets/ccc_quan.png" alt=""> -->
                            </p>
                        </div>
                        <div class="tu_text" v-show="danwei_shili">
                            <p>
                                <span>单位:{{danwei}}</span>
                            </p>
                            <p>
                                <span>本年目标销售额</span>
                                <span>本年目标销售额增长率</span>
                            </p>
                        </div>
                        <div class="bingpai_tu">
                            <div id="myCharts4_da" :style="{width:'48%',height:'80%',background:'#fff'}"></div>
                            <div id="myCharts5_da" :style="{width:'40%',height:'80%',background:'#fff'}"></div>
                        </div>
                        <ul class="shili"  v-show="danwei_shili">
                            <li>
                                <span style="background:#FFB466;"></span>目标
                            </li>
                            <li>
                                <span style="background:#6ABFA7;"></span>超出目标
                            </li>
                            <li>
                                <span style="background:#F16977;"></span>降低目标
                            </li>
                        </ul>
                    </div>
                    <div class="tu2_con_right">
                        <p class="tu2_con_right_tit">
                            <span class="span1">年份:{{nianfen}}</span>
                            <span class="span2">
                                <span @click="dian_yiji2_da" :style="yierji_panduan2_da?'color:#FFB466;':''"><img :src="yierji_panduan2_da?require('../../assets/huang_shixin.png'):require('../../assets/ccc_quan.png')">一级</span>
                                <span @click="dian_mingxi2_da" :style="!yierji_panduan2_da?'color:#FFB466;':''"><img :src="!yierji_panduan2_da?require('../../assets/huang_shixin.png'):require('../../assets/ccc_quan.png')">明细</span>
                            </span>
                        </p>
                        <p class="tu2_right_danwei">
                            <span>单位:{{danwei}}</span>
                            <span>本年目标销售额产品占比</span>
                            <span></span>
                        </p>
                        <div id="myCharts6_da" v-if='yierji_panduan2_da' :style="{width:'80%',height:'80%',background:'#fff'}"></div>
                        <div id="myCharts7_da" v-show='!yierji_panduan2_da' :style="{width:'80%',height:'80%',background:'#fff'}"></div>
                    </div>
                </div>
            </div>
        </div>
  </div> 
</template>

<script>
import { generate_as_prod_aim_bar_chart, query_as_year_list, generate_as_prod_aim_pie_chart } from '../../api/api'
export default {
  name: 'figuretwo',
  data () {
    return {
      time_list: [], // 存在的年份
      danwei: '元', // 单位
      danwei_shili: true,
      yu_shangnian_duibi: true, // 与上年对比文字是否展示
      nianfen: '',
      yierji_panduan: true,
      yierji_panduan2: true,
      yierji_panduan2_da: true,
      xiao: {
        yiji_yzhou: [],
        yiji_chengse: [],
        yiji_lvse: [],
        yiji_hongse: [],
        yiji_zengzhang_lv: [],
        erji_yzhou: [],
        erji_chengse: [],
        erji_lvse: [],
        erji_hongse: [],
        erji_zengzhang_lv: [],
        yiji_yuan: [],
        erji_yuan: []
      },
      quan_zia: '',
      you_xiaoping: true,

      zhi_paixu: [],
      zhi_paixu2: [],
      zhengxu: false,
      zengzang_paixu: [],
      zengzang_paixu2: []
    }
  },
  computed: {
  },
  props: {
    //   tuchu_chuanshu:String,
    //   tu2_quanzi:String,
    //   required: true,
  },
  watch: {
    //   tu2_quanzi(val){
    //     console.log(val)
    //     this.quan_zia=val
    //     this.nianfen_zhi()
    //     this.daping_tu()
    // },
    // quan_zia(){
    //     if(this.quan_zia==1){
    //         this.nianfen_zhi()
    //         this.daping_tu()
    //     }
    // }
  },
  mounted () {
    this.jichu()
  },
  methods: {
    // 基础文字
    jichu () {
      query_as_year_list({
        data: {
          ent_id: this.$ent_id(),
          aim_type: '2'
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10237) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          const list = []
          if (date[0] != null) {
            for (let i = 0; i < date.length; i++) {
              list.push(date[i] + '年')
            }
          }
          date.forEach(it => {
            if (it == new Date().getFullYear()) {
              this.nianfen = new Date().getFullYear() + '年'
              this.nianfen_zhi()
            }
          })
          this.time_list = list
        } else if (res.data.code == 10238) {}
      })
    },
    // 年份选择
    nianfen_zhi () {
      if (this.nianfen != '') {
        generate_as_prod_aim_bar_chart({
          data: {
            ent_id: this.$ent_id(),
            year: this.nianfen.slice(0, 4)
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.list1)
            const data = JSON.parse(res.data.body.list2)
            console.log(date)
            console.log(data)
            if (date != undefined) {
              this.zhi_paixu = date
              this.xiao.yiji_yzhou = []
              this.xiao.yiji_chengse = []
              this.xiao.yiji_lvse = []
              this.xiao.yiji_hongse = []
              this.xiao.yiji_zengzhang_lv = []
              let zengzang_pai = []
              for (let i = 0; i < date.length; i++) {
                this.xiao.yiji_yzhou.push(date[i].cat_name)
                this.xiao.yiji_chengse.push(date[i].orange)
                this.xiao.yiji_lvse.push(date[i].green)
                this.xiao.yiji_hongse.push(date[i].red)
                this.xiao.yiji_zengzhang_lv.push(Number(date[i].rate_growth_sale_aim_est).toFixed(2))
                if (date[i].red != 0) {
                  zengzang_pai.push(date[i])
                }
                if (date[i].green != 0) {
                  zengzang_pai.push(date[i])
                }
              }
              zengzang_pai = this.$paixu(zengzang_pai, 'rate_growth_sale_aim_est')
              const zait = []
              for (let a = zengzang_pai.length - 1; a >= 0; a--) {
                zait.push(zengzang_pai[a])
              }
              zait.forEach((ite, inde) => {
                this.$set(ite, 'zhi', inde + 1)
                this.$set(ite, 'name', ite.cat_name)
              })
              this.zengzang_paixu = zait
              this.yierji_panduan = true
              if (this.you_xiaoping) {
                this.tupian4()
                this.tupian5()
                if (this.quan_zia) {
                  this.tupian4_da()
                  this.tupian5_da()
                }
              }
            }
            if (data != undefined) {
              console.log(data)
              this.zhi_paixu2 = data
              this.xiao.erji_yzhou = []
              this.xiao.erji_chengse = []
              this.xiao.erji_lvse = []
              this.xiao.erji_hongse = []
              this.xiao.erji_zengzhang_lv = []
              let zengzang_pai2 = []
              for (let i = 0; i < data.length; i++) {
                this.xiao.erji_yzhou.push(data[i].prod_name)
                this.xiao.erji_chengse.push(data[i].orange)
                this.xiao.erji_lvse.push(data[i].green)
                this.xiao.erji_hongse.push(data[i].red)
                this.xiao.erji_zengzhang_lv.push(Number(data[i].rate_growth_sale_aim_est).toFixed(2))
                if (data[i].red != 0) {
                  zengzang_pai2.push(data[i])
                }
                if (data[i].green != 0) {
                  zengzang_pai2.push(data[i])
                }
              }
              zengzang_pai2 = this.$paixu(zengzang_pai2, 'rate_growth_sale_aim_est')
              const zaiti = []
              for (let a = zengzang_pai2.length - 1; a >= 0; a--) {
                zaiti.push(zengzang_pai2[a])
              }
              zaiti.forEach((item, indem) => {
                this.$set(item, 'zhi', indem + 1)
                this.$set(item, 'name', item.prod_name)
              })
              this.zengzang_paixu2 = zaiti
            }
          } else if (res.data.code == 201) {}
        })
        generate_as_prod_aim_pie_chart({
          data: {
            ent_id: this.$ent_id(),
            year: this.nianfen.slice(0, 4)
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            if (date != undefined) {
              this.xiao.yiji_yuan = []
              this.xiao.erji_yuan = []
              for (let i = 0; i < date.length; i++) {
                this.xiao.yiji_yuan.push({
                  name: date[i].cat_name,
                  value: date[i].amt_aim
                })
                if (date[i].child_flag == 1) {
                  for (let a = 0; a < date[i].as_prod_aim_list.length; a++) {
                    this.xiao.erji_yuan.push({
                      name: date[i].as_prod_aim_list[a].prod_name,
                      value: date[i].as_prod_aim_list[a].amt_sale_aim
                    })
                  }
                }
              }
              this.yierji_panduan2 = true
              this.yierji_panduan2_da = true
              if (this.you_xiaoping) {
                this.tupian6()
                if (this.quan_zia) {
                  this.tupian6_da()
                }
              }
            }
          } else if (res.data.code == 201) {}
        })
        this.$emit('tu2_nianfen', this.nianfen)
      }
    },
    // 计算
    jisuan () {
    },
    tupian4 () {
      const echarts = require('echarts')
      const myChart = echarts.init(document.getElementById('myCharts4'))
        
      myChart.setOption({
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          top: '2%',
          containLabel: true
        },
        xAxis:
                {
                  type: 'value',
                  position: 'top',
                  axisTick: {
                    show: false
                  },
                  boundaryGap: false,
                  axisLine: {
                    show: false
                  },
                  axisLabel: {
                    color: '#A0A4AA',
                    fontSize: 10
                  }
                },
        yAxis: {
          type: 'category',
          data: this.xiao.yiji_yzhou,
          axisTick: {
            show: false
          },
          axisLine: {
            lineStyle: {
              color: '#BDC8D3'
            }
          },
          axisLabel: {
            color: '#4C4A4D',
            fontSize: 12
          }
        },
        series: [
          {
            type: 'bar',
            stack: 'zong',
            barWidth: '40%',
            label: {
              show: true,
              align: 'center',
              formatter: params => {
                if (params.value != 0) {
                  return params.value
                } else {
                  return ''
                }
              }
            },
            itemStyle: {
              color: '#FFB466'
            },
            data: this.xiao.yiji_chengse
          },
          {
            type: 'bar',
            stack: 'zong',
            barWidth: '40%',
            label: {
              show: true,
              align: 'center',
              formatter: params => {
                if (params.value != 0) {
                  return params.value
                } else {
                  return ''
                }
              }
            },
            itemStyle: {
              color: '#6ABFA7'
            },
            data: this.xiao.yiji_lvse
          },
          {
            type: 'bar',
            stack: 'zong',
            barWidth: '40%',
            label: {
              show: true,
              align: 'center',
              formatter: params => {
                if (params.value != 0) {
                  return -params.value
                } else {
                  return ''
                }
              }
            },
            itemStyle: {
              color: '#F16977'
            },
            data: this.xiao.yiji_hongse
          }
        ]
      })
    },
    tupian5 () {
      const echarts = require('echarts')
      const myChart = echarts.init(document.getElementById('myCharts5'))
      myChart.setOption({
        grid: {
          left: '4%',
          right: '10%',
          bottom: '3%',
          top: '2%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          position: 'top',
          axisTick: {
            show: false
          },
          boundaryGap: false,
          axisLine: {
            show: false
          },
          axisLabel: {  
            show: true,  
            interval: 'auto',  
            formatter: '{value} %',
            color: '#A0A4AA',
            fontSize: 10
          }
        },
        yAxis: {
          type: 'category',
          data: this.xiao.yiji_yzhou,
          axisLine: {
            lineStyle: {
              color: '#BDC8D3'
            }
          },
          axisTick: {
            show: false
          },
          // axisLabel:{
          //     show:false,
          // },
          splitLine: {
            show: false
          }
        },
        series: [
          {
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 1,
              color: '#9FBBFE'
            },
            showSymbol: true,
            symbol: 'circle', // 设定为实心点
            symbolSize: 20, // 设定实心点的大小
            data: this.xiao.yiji_zengzhang_lv,
            itemStyle: {
              color: parmas => {
                console.log(parmas)
                if (Number(parmas.value) >= 0) {
                  return '#6ABFA7'
                }
                if (Number(parmas.value) < 0) {
                  return '#F16977'
                }
              }
            },
            label: {
              normal: {
                show: true,
                position: 'right',
                formatter: params => {
                  console.log(params)
                  if (params.value != 0) {
                    for (let i = 0; i < this.zengzang_paixu.length; i++) {
                      if (params.name == this.zengzang_paixu[i].name) {
                        return this.zengzang_paixu[i].zhi
                      }
                    }
                  } else {
                    return ''
                  }
                }
              }
            }
          }
        ]
      })
    },
    tupian6 () {
      this.xiao.yiji_yuan.map((item, index) => {
        if(item.value == 0) {
          this.xiao.yiji_yuan.splice(index, 1)
        }
      })
      let echartsData =  this.xiao.yiji_yuan
      const echarts = require('echarts')
      const myChart = echarts.init(document.getElementById('myCharts6'))
      myChart.setOption({
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        series: [
          {
            name: '访问来源',
            type: 'pie',
            radius: '55%',
            center: ['50%', '50%'],
            data: echartsData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      })
    },
    tupian7 () {
      this.xiao.yiji_yuan.map((item, index) => {
        if(item.value == 0) {
          this.xiao.yiji_yuan.splice(index, 1)
        }
      })
      let echartsData =  this.xiao.yiji_yuan
      const echarts = require('echarts')
      const myChart = echarts.init(document.getElementById('myCharts7'))
      myChart.setOption({
        tooltip: {
          trigger: 'item',
          formatter: '{b}: {c} ({d}%)'
        },
        series: [
          {
            type: 'pie',
            selectedMode: 'single',
            radius: [0, '38%'],

            label: {
              position: 'inner'
            },
            labelLine: {
              show: false
            },
            data: echartsData
          },
          {
            type: 'pie',
            radius: ['40%', '55%'],
            label: {
              formatter: '{b}',
              color: '#000000',
              // backgroundColor: '#eee',
              // borderColor: '#000000',
              // borderWidth: 1,
              // borderRadius: 4,
              // shadowBlur:3,
              // shadowOffsetX: 2,
              // shadowOffsetY: 2,
              // shadowColor: '#999',
              // padding: [0, 7],
              rich: {
                a: {
                  color: '#000',
                  lineHeight: 22,
                  align: 'center'
                },
                hr: {
                  borderColor: '#aaa',
                  width: '100%',
                  borderWidth: 0.5,
                  height: 0
                },
                b: {
                  fontSize: 16,
                  lineHeight: 33
                },
                per: {
                  color: '#eee',
                  backgroundColor: '#334455',
                  padding: [2, 4],
                  borderRadius: 2
                }
              }
            },
            data: echartsData
          }
        ]
      })
    },
    daping_tu () {
      setTimeout(() => {
        this.tupian4_da()
        this.tupian5_da()
        this.tupian6_da()
        this.tupian7_da()
      }, 0)
    },
    tupian4_da () {
      const echarts = require('echarts')
      const myChart = echarts.init(document.getElementById('myCharts4_da'))
        
      myChart.setOption({
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          top: '2%',
          containLabel: true
        },
        xAxis:
                {
                  type: 'value',
                  position: 'top',
                  axisTick: {
                    show: false
                  },
                  boundaryGap: false,
                  axisLine: {
                    show: false
                  },
                  axisLabel: {
                    color: '#A0A4AA',
                    fontSize: 10
                  }
                },
        yAxis: {
          type: 'category',
          data: this.xiao.yiji_yzhou,
          axisTick: {
            show: false
          },
          axisLine: {
            lineStyle: {
              color: '#BDC8D3'
            }
          },
          axisLabel: {
            color: '#4C4A4D',
            fontSize: 12
          }
        },
        series: [
          {
            type: 'bar',
            stack: 'zong',
            barWidth: '40%',
            label: {
              show: true,
              align: 'center',
              formatter: params => {
                if (params.value != 0) {
                  return params.value
                } else {
                  return ''
                }
              }
            },
            itemStyle: {
              color: '#FFB466'
            },
            data: this.xiao.yiji_chengse
          },
          {
            type: 'bar',
            stack: 'zong',
            barWidth: '40%',
            label: {
              show: true,
              align: 'center',
              formatter: params => {
                if (params.value != 0) {
                  return params.value
                } else {
                  return ''
                }
              }
            },
            itemStyle: {
              color: '#6ABFA7'
            },
            data: this.xiao.yiji_lvse
          },
          {
            type: 'bar',
            stack: 'zong',
            barWidth: '40%',
            label: {
              show: true,
              align: 'center',
              formatter: params => {
                if (params.value != 0) {
                  return -params.value
                } else {
                  return ''
                }
              }
            },
            itemStyle: {
              color: '#F16977'
            },
            data: this.xiao.yiji_hongse
          }
        ]
      })
    },
    tupian5_da () {
      const echarts = require('echarts')
      const myChart = echarts.init(document.getElementById('myCharts5_da'))
      myChart.setOption({
        grid: {
          left: '4%',
          right: '8%',
          bottom: '3%',
          top: '2%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          position: 'top',
          axisTick: {
            show: false
          },
          boundaryGap: false,
          axisLine: {
            show: false
          },
          axisLabel: {  
            show: true,  
            interval: 'auto',  
            formatter: '{value} %',
            color: '#A0A4AA',
            fontSize: 10
          }
        },
        yAxis: {
          type: 'category',
          data: this.xiao.yiji_yzhou,
          axisLine: {
            lineStyle: {
              color: '#BDC8D3'
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false
          },
          splitLine: {
            show: false
          }
        },
        series: [
          {
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 1,
              color: '#9FBBFE'
            },
            showSymbol: true,
            symbol: 'circle', // 设定为实心点
            symbolSize: 20, // 设定实心点的大小
            data: this.xiao.yiji_zengzhang_lv,
            itemStyle: {
              color: parmas => {
                console.log(parmas)
                if (Number(parmas.value) >= 0) {
                  return '#6ABFA7'
                }
                if (Number(parmas.value) < 0) {
                  return '#F16977'
                }
              }
            },
            label: {
              normal: {
                show: true,
                position: 'right',
                formatter: params => {
                  console.log(params)
                  if (params.value != 0) {
                    for (let i = 0; i < this.zengzang_paixu.length; i++) {
                      if (params.name == this.zengzang_paixu[i].name) {
                        return this.zengzang_paixu[i].zhi
                      }
                    }
                  } else {
                    return ''
                  }
                }
              }
            }
          }
        ]
      })
    },
    tupian6_da () {
      const echarts = require('echarts')
      const myChart = echarts.init(document.getElementById('myCharts6_da'))
      myChart.setOption({
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        series: [
          {
            name: '访问来源',
            type: 'pie',
            radius: '55%',
            center: ['50%', '50%'],
            data: this.xiao.yiji_yuan,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      })
    },
    tupian7_da () {
      const echarts = require('echarts')
      const myChart = echarts.init(document.getElementById('myCharts7_da'))
      myChart.setOption({
        tooltip: {
          trigger: 'item',
          formatter: '{b}: {c} ({d}%)'
        },
        series: [
          {
            type: 'pie',
            selectedMode: 'single',
            radius: [0, '38%'],

            label: {
              position: 'inner'
            },
            labelLine: {
              show: false
            },
            data: this.xiao.yiji_yuan
          },
          {
            type: 'pie',
            radius: ['40%', '55%'],
            label: {
              formatter: '{b}',
              color: '#000000',
              // backgroundColor: '#eee',
              // borderColor: '#000000',
              // borderWidth: 1,
              // borderRadius: 4,
              // shadowBlur:3,
              // shadowOffsetX: 2,
              // shadowOffsetY: 2,
              // shadowColor: '#999',
              // padding: [0, 7],
              rich: {
                a: {
                  color: '#000',
                  lineHeight: 22,
                  align: 'center'
                },
                hr: {
                  borderColor: '#aaa',
                  width: '100%',
                  borderWidth: 0.5,
                  height: 0
                },
                b: {
                  fontSize: 16,
                  lineHeight: 33
                },
                per: {
                  color: '#eee',
                  backgroundColor: '#334455',
                  padding: [2, 4],
                  borderRadius: 2
                }
              }
            },
            data: this.xiao.erji_yuan
          }
        ]
      })
    },
    // 点一级二级
    dian_yiji () {
      if (!this.yierji_panduan) {
        let yzhou = ''
        let chengse = ''
        let lvse = ''
        let hongse = ''
        let zengzhang_lv = ''
        yzhou = this.xiao.erji_yzhou
        chengse = this.xiao.erji_chengse
        lvse = this.xiao.erji_lvse
        hongse = this.xiao.erji_hongse
        zengzhang_lv = this.xiao.erji_zengzhang_lv
        this.xiao.erji_yzhou = this.xiao.yiji_yzhou
        this.xiao.erji_chengse = this.xiao.yiji_chengse
        this.xiao.erji_lvse = this.xiao.yiji_lvse
        this.xiao.erji_hongse = this.xiao.yiji_hongse
        this.xiao.erji_zengzhang_lv = this.xiao.yiji_zengzhang_lv
        this.xiao.yiji_yzhou = yzhou
        this.xiao.yiji_chengse = chengse
        this.xiao.yiji_lvse = lvse
        this.xiao.yiji_hongse = hongse
        this.xiao.yiji_zengzhang_lv = zengzhang_lv
        const zhan = this.zengzang_paixu2
        this.zengzang_paixu2 = this.zengzang_paixu
        this.zengzang_paixu = zhan
        this.tupian4()
        this.tupian5()
        if (this.quan_zia) {
          this.tupian4_da()
          this.tupian5_da()
        }
        this.yierji_panduan = true
      }
    },
    dian_mingxi () {
      if (this.yierji_panduan) {
        let yzhou = ''
        let chengse = ''
        let lvse = ''
        let hongse = ''
        let zengzhang_lv = ''
        yzhou = this.xiao.yiji_yzhou
        chengse = this.xiao.yiji_chengse
        lvse = this.xiao.yiji_lvse
        hongse = this.xiao.yiji_hongse
        zengzhang_lv = this.xiao.yiji_zengzhang_lv
        this.xiao.yiji_yzhou = this.xiao.erji_yzhou
        this.xiao.yiji_chengse = this.xiao.erji_chengse
        this.xiao.yiji_lvse = this.xiao.erji_lvse
        this.xiao.yiji_hongse = this.xiao.erji_hongse
        this.xiao.yiji_zengzhang_lv = this.xiao.erji_zengzhang_lv
        this.xiao.erji_yzhou = yzhou
        this.xiao.erji_chengse = chengse
        this.xiao.erji_lvse = lvse
        this.xiao.erji_hongse = hongse
        this.xiao.erji_zengzhang_lv = zengzhang_lv
        this.yierji_panduan = false
        const zhan = this.zengzang_paixu
        this.zengzang_paixu = this.zengzang_paixu2
        this.zengzang_paixu2 = zhan
        this.tupian4()
        this.tupian5()
        if (this.quan_zia) {
          this.tupian4_da()
          this.tupian5_da()
        }
      }
    },
    dian_yiji2 () {
      if (!this.yierji_panduan2) {
        this.yierji_panduan2 = true
        setTimeout(() => {
          this.tupian6()
        }, 0)
      }
    },
    dian_mingxi2 () {
      if (this.yierji_panduan2) {
        this.yierji_panduan2 = false
        setTimeout(() => {
          this.tupian7()
        }, 0)
      }
    },
    dian_yiji2_da () {
      if (!this.yierji_panduan2_da) {
        this.yierji_panduan2_da = true
        setTimeout(() => {
          this.tupian6_da()
        }, 0)
      }
    },
    dian_mingxi2_da () {
      if (this.yierji_panduan2_da) {
        this.yierji_panduan2_da = false
        setTimeout(() => {
          this.tupian7_da()
        }, 0)
      }
    },
    // // 点击关闭
    // dianji_guanbi(){
    //     this.$router.push('/aspreackeven')
    //     this.quan_zia=''
    //     this.$emit('tu2_nianfen', '2')
    //     sessionStorage.setItem("pc_mlbb_mubiao_biao", '-1');
    // },
    // 点击关闭
    dianji_guanbi () {
      // this.$router.push('/aspreackeven')
      // this.quan_zia=''
      // this.$emit('nianfen_fu', '1')
      // sessionStorage.setItem("pc_mlbb_mubiao_biao", '-1');
      if (this.biaoge_nei) {
        this.quan_zia = false
        this.you_xiaoping = false
      } else {
        this.quan_zia = false
      }
      this.yierji_panduan2_da = true
    },
    biaoge_dakai () {
      this.you_xiaoping = false
    },
    dakai_quanping (data) {
      console.log(data)
      this.you_xiaoping = true
      this.nianfen = data.time
      this.quan_zia = data.chutu
      this.biaoge_nei = data.biaoge_nei
      // if(data.chutu){
      this.nianfen_zhi()
      // this.nianfen_zhi2()
      // }else{
      //     // this.nianfen_zhi2()
      // }
    },

    // 排序
    chanpin_mubiao_paixu () {
      let date = []
      if (this.yierji_panduan) {
        const data = this.$paixu(this.zhi_paixu, 'amt_aim')
        if (this.zhengxu) {
          for (let i = data.length - 1; i >= 0; i--) {
            date.push(data[i])
          }
        } else {
          date = data
        }
      } else {
        const data = this.$paixu(this.zhi_paixu2, 'amt_sale_aim')
        if (this.zhengxu) {
          for (let i = data.length - 1; i >= 0; i--) {
            date.push(data[i])
          }
        } else {
          date = data
        }
      }
      console.log(date)
      this.xiao.yiji_yzhou = []
      this.xiao.yiji_chengse = []
      this.xiao.yiji_lvse = []
      this.xiao.yiji_hongse = []
      this.xiao.yiji_zengzhang_lv = []
      for (let i = 0; i < date.length; i++) {
        this.xiao.yiji_yzhou.push(this.yierji_panduan ? date[i].cat_name : date[i].prod_name)
        this.xiao.yiji_chengse.push(date[i].orange)
        this.xiao.yiji_lvse.push(date[i].green)
        this.xiao.yiji_hongse.push(date[i].red)
        this.xiao.yiji_zengzhang_lv.push(Number(date[i].rate_growth_sale_aim_est).toFixed(2))
      }
      // this.zhi_paixu2=data
      // this.xiao.erji_yzhou=[]
      // this.xiao.erji_chengse=[]
      // this.xiao.erji_lvse=[]
      // this.xiao.erji_hongse=[]
      // this.xiao.erji_zengzhang_lv=[]
      // for(let i=0;i<data.length;i++){
      //     this.xiao.erji_yzhou.push(data[i].prod_name)
      //     this.xiao.erji_chengse.push(data[i].orange)
      //     this.xiao.erji_lvse.push(data[i].green)
      //     this.xiao.erji_hongse.push(data[i].red)
      //     this.xiao.erji_zengzhang_lv.push(Number(data[i].rate_growth_sale_aim_est).toFixed(2))
      // }
      this.tupian4()
      this.tupian5()
      if (this.quan_zia) {
        this.tupian4_da()
        this.tupian5_da()
      }
      this.zhengxu = !this.zhengxu
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.figuretwo {
    height:100%;
    width:100%;
    // position: relative;
    .xiaoping{
        height:100%;
        width:100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .tu2_con_left{
            height:3.88rem;
            width:59%;
            background:#fff;
            border-radius: 0.04rem;
            position: relative;
            .tu2_con_left_tit{
                display: flex;
                justify-content: space-between;
                padding-top:0.18rem;
                margin:0 0.16rem;
                .xuanze_nianfen{
                    font-size: 0.12rem;
                    color:#4C4A4D;
                    display: flex;
                    align-items: center;
                }
                .yierji{
                    display: flex;
                    align-items: center;
                    span{
                        display: flex;
                        align-items: center;
                        font-size: 0.12rem;
                        cursor: pointer;
                        &:nth-child(1){
                            margin-right:0.19rem;
                        }
                        img{
                            margin-right:0.07rem;
                            height: 0.17rem;
                            width:0.17rem;
                        }
                    }
                }
                .paixu{
                    display: flex;
                    align-items: center;
                    font-size: 0.13rem;
                    color:#4C4A4D;
                    cursor: pointer;
                    img{
                        height:0.18rem;
                        width:0.18rem;
                    }
                }
            }
            .tu_text{
                position: absolute;
                top:0.55rem;
                left: 0.32rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width:76.5%;
                p{
                    &:nth-child(1){
                        span{
                            font-size: 0.1rem;
                            color: #4C4A4D;
                        }
                    }
                    &:nth-child(2){
                        width:90%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 0.12rem;
                        color: #4C4A4D;
                        span{
                            display: inline-block;
                            margin: 0 12%;
                        }
                    }
                }
            }
            .bingpai_tu{
                height:100%;
                width:100%;
                display: flex;
                align-items: center;
            }
            .shili{
                position: absolute;
                right:4%;
                top:0.98rem;
                li{
                    display: flex;
                    align-items: center;
                    font-size: 0.12rem;
                    color:#4C4A4D;
                    height:0.26rem;
                    span{
                        display: block;
                        height:0.08rem;
                        width:0.08rem;
                        margin-right: 0.03rem;
                    }
                }
            }
        }
        .tu2_con_right{
            height:3.88rem;
            width:39%;
            background:#fff;
            border-radius: 0.04rem;
            .tu2_con_right_tit{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-top:0.18rem;
                margin:0 0.16rem;
                .span1{
                    font-size: 0.12rem;
                    color:#4C4A4D;
                }
                .span2{
                    display: flex;
                    align-items: center;
                    span{
                        display: flex;
                        align-items: center;
                        font-size: 0.12rem;
                        color:#4C4A4D;
                        cursor: pointer;
                        img{
                            height:0.17rem;
                            width:0.17rem;
                            margin-right: 0.07rem;
                        }
                        &:nth-child(1){
                            margin-right: 0.28rem;
                        }
                    }
                }
            }
            .tu2_right_danwei{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-top:0.29rem;
                margin:0 0.16rem;
                span{
                    color:#4C4A4D;
                    &:nth-child(1){
                        font-size: 0.1rem;
                    }
                    &:nth-child(2){
                        font-size: 0.12rem;
                    }
                }
            }
            #myCharts6{
                margin: 0 auto;
            }
            #myCharts7{
                margin: 0 auto;
            }
        }
    }
    .quanping{
        height:100%;
        width:100%;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: auto;
        margin: 0;
        z-index:999;
        background: rgba(0,0,0,0.8);
        .box{
            position: relative;
            height:90%;
            width: 14rem;
            left:50%;
            top:5%;
            margin-left:-8rem;
            background: #F1F3F9;
            .guanbi{
                position: absolute;
                top:-0.2rem;
                right:-0.2rem;
                height:0.6rem;
                width: 0.6rem;
                border-radius: 50%;
                background: #fc6a6a;
                color:#fff;
                text-align: center;
                font-size: 0.3rem;
                line-height: 0.6rem;
                box-shadow:0 0 0.05rem 0.05rem rgba(27, 27, 27, 0.5);
                cursor: pointer;
            }
            .box_text{
                position: absolute;
                font-size: 0.17rem;
                color:#4C4A4D;
                left:5%;
                top:3%;
            }
            .box_nei{
                position: relative;
                height:86%;
                width: 90%;
                left:5%;
                top:7%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .tu2_con_left{
                    height:6rem;
                    width:59%;
                    background:#fff;
                    border-radius: 0.04rem;
                    position: relative;
                    .tu2_con_left_tit{
                        display: flex;
                        justify-content: space-between;
                        padding-top:0.18rem;
                        margin:0 0.16rem;
                        .xuanze_nianfen{
                            font-size: 0.12rem;
                            color:#4C4A4D;
                            display: flex;
                            align-items: center;
                        }
                        .yierji{
                            display: flex;
                            align-items: center;
                            span{
                                display: flex;
                                align-items: center;
                                font-size: 0.12rem;
                                cursor: pointer;
                                &:nth-child(1){
                                    margin-right:0.19rem;
                                }
                                img{
                                    margin-right:0.07rem;
                                    height: 0.17rem;
                                    width:0.17rem;
                                }
                            }
                        }
                        .paixu{
                            display: flex;
                            align-items: center;
                            font-size: 0.13rem;
                            color:#4C4A4D;
                            cursor: pointer;
                            img{
                                height:0.18rem;
                                width:0.18rem;
                            }
                        }
                    }
                    .tu_text{
                        position: absolute;
                        top:0.55rem;
                        left: 0.32rem;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width:76.5%;
                        p{
                            &:nth-child(1){
                                span{
                                    font-size: 0.1rem;
                                    color: #4C4A4D;
                                }
                            }
                            &:nth-child(2){
                                width:93%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-size: 0.12rem;
                                color: #4C4A4D;
                                span{
                                    display: inline-block;
                                    margin: 0 12%;
                                }
                            }
                        }
                    }
                    .bingpai_tu{
                        height:100%;
                        width:100%;
                        display: flex;
                        align-items: center;
                    }
                    .shili{
                        position: absolute;
                        right:4%;
                        top:0.98rem;
                        li{
                            display: flex;
                            align-items: center;
                            font-size: 0.12rem;
                            color:#4C4A4D;
                            height:0.26rem;
                            span{
                                display: block;
                                height:0.08rem;
                                width:0.08rem;
                                margin-right: 0.03rem;
                            }
                        }
                    }
                }
                .tu2_con_right{
                    height:6rem;
                    width:39%;
                    background:#fff;
                    border-radius: 0.04rem;
                    .tu2_con_right_tit{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding-top:0.18rem;
                        margin:0 0.16rem;
                        .span1{
                            font-size: 0.12rem;
                            color:#4C4A4D;
                        }
                        .span2{
                            display: flex;
                            align-items: center;
                            span{
                                display: flex;
                                align-items: center;
                                font-size: 0.12rem;
                                color:#4C4A4D;
                                cursor: pointer;
                                img{
                                    height:0.17rem;
                                    width:0.17rem;
                                    margin-right: 0.07rem;
                                }
                                &:nth-child(1){
                                    margin-right: 0.28rem;
                                }
                            }
                        }
                    }
                    .tu2_right_danwei{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding-top:0.29rem;
                        margin:0 0.16rem;
                        span{
                            color:#4C4A4D;
                            &:nth-child(1){
                                font-size: 0.1rem;
                            }
                            &:nth-child(2){
                                font-size: 0.12rem;
                            }
                        }
                    }
                    #myCharts6{
                        margin: 0 auto;
                    }
                    #myCharts7{
                        margin: 0 auto;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1600px) {
        .quanping {
            width:1600px;
        }
    }
}
</style>
